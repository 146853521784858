/**
 * fileDownload
 * ------------
 * Allows downloading of data (binary or text) as a file directly from the browser,
 * without requiring server-side involvement for file generation.
 *
 * @param {Blob | string} data - The data to be downloaded.
 * @param {string} filename - The name for the downloaded file.
 * @param {string} [mimeType] - (Optional) MIME type of the file. Defaults to 'application/octet-stream'.
 */
export const fileDownload = (
  data,
  filename,
  mimeType = 'application/octet-stream'
) => {
  if (!data) return;

  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
