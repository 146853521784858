import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { FORMAT_DEFAULT_MONTH, links } from '../../utils';
import { isAdmin } from '../../utils/roles';
import { Fields, getFormValues, reduxForm } from 'redux-form/lib/immutable';
import { MonthRangeField, MonthYearField } from '../../atoms/reduxForms';
import React, { Component } from 'react';
import { formElectricConsumptionReportFields } from '../../constants/forms/formElectricConsumptionReportFields';
import { FORM_ELECTRIC_CONSUMPTION_REPORT } from '../../constants/formKeys';
import { getRole } from '../../reducers/userReducer';
import { getElectricConsumptionReport } from '../../reducers/electricConsumptionReportReducer';
import * as actions from '../../actions/actionCreators/electricConsumptionReportActionCreators';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { electricConsumptionReportFields } from '../../constants/ElectricConsumptionReport';
import moment from 'moment';
import { Button, Col, Radio, Row, Spin, Table } from 'antd';
import { FormActions } from '../../components';
import { numberFormatter } from '../../utils/formaters';

export class ElectricConsumptionReport extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    formValues: PropTypesImmutable.map,
    fetchElectricConsumptionReportItemsPage: PropTypes.func.isRequired,
    resetElectricConsumptionReport: PropTypes.func.isRequired,
    electricConsumptionReport: PropTypes.object,
  };

  state = {
    filter: true,
    loading: false,
    monthFilter: true,
  };

  handleClear = () => {
    this.props.reset();
  };

  handleGenerate = async newPage => {
    const { electricConsumptionReport } = this.props;
    const { formValues } = this.props;
    const { monthFilter } = this.state;
    const { fetchElectricConsumptionReportItemsPage } = this.props;
    const { location } = this.props;
    const { pageSize, page } = electricConsumptionReport.toJSON();

    const params = {};

    if (formValues) {
      Object.assign(
        params,
        Object.entries(formValues.toJS()).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        )
      );

      if (monthFilter) {
        delete params[formElectricConsumptionReportFields.DATE_TO];
        delete params[formElectricConsumptionReportFields.DATE_FROM];
      } else {
        delete params[formElectricConsumptionReportFields.YEAR];
        delete params[formElectricConsumptionReportFields.MONTH];
      }
    }

    const query = Object.fromEntries(new URLSearchParams(location.search));
    if (query.transactionId) {
      params[formElectricConsumptionReportFields.TRANSACTION_ID] =
        query.transactionId;
    }

    params[formElectricConsumptionReportFields.PAGE] = newPage
      ? newPage - 1
      : page ? page - 1 : 0;
    params[formElectricConsumptionReportFields.PAGE_SIZE] = pageSize || 20;

    this.setState({ loading: true });

    await fetchElectricConsumptionReportItemsPage(params);

    this.setState({ loading: false });
  };

  getGenerateXlsUrl() {
    const { formValues } = this.props;
    const { monthFilter } = this.state;

    const params = {};

    if (formValues) {
      Object.assign(
        params,
        Object.entries(formValues.toJS()).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        )
      );

      if (monthFilter) {
        delete params[formElectricConsumptionReportFields.DATE_FROM];
        delete params[formElectricConsumptionReportFields.DATE_TO];
      } else {
        delete params[formElectricConsumptionReportFields.MONTH];
        delete params[formElectricConsumptionReportFields.YEAR];
      }
    }

    const paramString = new URLSearchParams(params);
    return links.electricConsumptionReportXls + '?' + paramString.toString();
  }

  renderFilterField = () => {
    const { t } = this.props;
    const { monthFilter } = this.state;
    if (monthFilter) {
      return (
        <Fields
          label={t('electricConsumptionReport.period')}
          names={[
            formElectricConsumptionReportFields.MONTH,
            formElectricConsumptionReportFields.YEAR,
          ]}
          className="date-field"
          component={MonthYearField}
          hasFeedback={false}
        />
      );
    }
    return (
      <Fields
        label={t('electricConsumptionReport.dateRange.title')}
        names={[
          formElectricConsumptionReportFields.DATE_FROM,
          formElectricConsumptionReportFields.DATE_TO,
        ]}
        placeholder={[
          t('electricConsumptionReport.dateRange.dateFromPlaceholder'),
          t('electricConsumptionReport.dateRange.dateToPlaceholder'),
        ]}
        component={MonthRangeField}
        hasFeedback={false}
      />
    );
  };

  getReportColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('electricConsumptionReport.transactionNumber'),
        dataIndex: electricConsumptionReportFields.TRANSACTION_NUMBER,
      },
      {
        title: t('electricConsumptionReport.consumptionPeriod'),
        dataIndex: electricConsumptionReportFields.CONSUMPTION_PERIOD,
      },
      {
        title: t('electricConsumptionReport.originalEnergyAmount'),
        dataIndex: electricConsumptionReportFields.ORIGINAL_ENERGY_AMOUNT,
        render: numberFormatter,
      },
      {
        title: t('electricConsumptionReport.naturalEnergyAmount'),
        dataIndex: electricConsumptionReportFields.NATURAL_ENERGY_AMOUNT,
        render: numberFormatter,
      },
      {
        title: t('electricConsumptionReport.calculatedMultiplier'),
        dataIndex: electricConsumptionReportFields.CALCULATED_MULTIPLIER,
      },
      {
        title: t('electricConsumptionReport.calculatedEnergyAmount'),
        dataIndex: electricConsumptionReportFields.CALCULATED_ENERGY_AMOUNT,
        render: numberFormatter,
      },
      {
        title: t('electricConsumptionReport.adjustedEnergyAmount'),
        dataIndex: electricConsumptionReportFields.ADJUSTED_ENERGY_AMOUNT,
        render: numberFormatter,
      },
      {
        title: t('electricConsumptionReport.ghgCapacity'),
        dataIndex: electricConsumptionReportFields.GHG_CAPACITY,
      },
    ];
  };

  onPageChange = page => {
    return this.handleGenerate(page);
  };

  // ugly workaround for empty formValues in componentDidMount
  waitInit() {
    if (!this.props.formValues) {
      setTimeout(() => {
        this.waitInit();
      }, 100);
    } else {
      this.handleGenerate(0);
    }
  }

  componentDidMount() {
    this.waitInit();
  }

  componentWillUnmount() {
    this.props.resetElectricConsumptionReport();
  }

  render() {
    const { t, electricConsumptionReport, location } = this.props;
    const { monthFilter } = this.state;

    const {
      data,
      page,
      totalElements,
      pageSize,
    } = electricConsumptionReport.toJSON();

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <form>
            <Row gutter={32}>
              <Col span={8}>
                <Radio.Group
                  name="filterModeGroup"
                  value={monthFilter}
                  onChange={e => this.setState({ monthFilter: e.target.value })}
                >
                  <Radio value>
                    {t('electricConsumptionReport.filterModeGroup.monthMode')}
                  </Radio>
                  <Radio value={false}>
                    {t('electricConsumptionReport.filterModeGroup.rangeMode')}
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8}>{this.renderFilterField()}</Col>
            </Row>
            <Row gutter={32}>
              <Table
                scroll={{ x: 'auto' }}
                rowKey={electricConsumptionReportFields.ID}
                columns={this.getReportColumns(t)}
                dataSource={data}
                bordered
                pagination={{
                  onChange: this.onPageChange,
                  current: page,
                  total: totalElements,
                  pageSize,
                  defaultCurrent: 1,
                }}
              />
            </Row>
            <FormActions>
              <Button onClick={this.handleClear}>
                {t('electricConsumptionReport.emptyButton')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  //remove transactionId from query if any present
                  this.props.history.replace(location.pathname);
                  this.handleGenerate();
                }}
              >
                {t('electricConsumptionReport.generate')}
              </Button>
              <a href={this.getGenerateXlsUrl()} rel="noopener noreferrer">
                <Button>{t('electricConsumptionReport.generateXls')}</Button>
              </a>
            </FormActions>
          </form>
        </Spin>
      </div>
    );
  }
}

const calcInitialDates = () => {
  return moment().month() === 0
    ? {
        [formElectricConsumptionReportFields.MONTH]: 12, // december
        [formElectricConsumptionReportFields.YEAR]:
          new Date().getFullYear() - 1,
        [formElectricConsumptionReportFields.DATE_FROM]: moment()
          .subtract(1, 'years')
          .startOf('year')
          .format(FORMAT_DEFAULT_MONTH),
        [formElectricConsumptionReportFields.DATE_TO]: moment()
          .subtract(1, 'years')
          .endOf('year')
          .format(FORMAT_DEFAULT_MONTH),
      }
    : {
        [formElectricConsumptionReportFields.MONTH]: new Date().getMonth(), // previous month
        [formElectricConsumptionReportFields.YEAR]: new Date().getFullYear(),
        [formElectricConsumptionReportFields.DATE_FROM]: moment()
          .startOf('year')
          .format(FORMAT_DEFAULT_MONTH),
        [formElectricConsumptionReportFields.DATE_TO]: moment().format(
          FORMAT_DEFAULT_MONTH
        ),
      };
};

const formValuesSelector = getFormValues(FORM_ELECTRIC_CONSUMPTION_REPORT);

const mapStateToProps = state => ({
  isUserAdmin: isAdmin(getRole(state)),
  initialValues: calcInitialDates(),
  formValues: formValuesSelector(state),
  electricConsumptionReport: getElectricConsumptionReport(state),
});

const mapDispatchToProps = {
  fetchElectricConsumptionReportItemsPage:
    actions.fetchElectricConsumptionReportItemsPage,
  resetElectricConsumptionReport: actions.ecrResetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_ELECTRIC_CONSUMPTION_REPORT,
    enableReinitialize: true,
  })(withRouter(ElectricConsumptionReport))
);
