import React from 'react';
import { DatePicker, Button, notification } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import './MeasuringSample.css';
import { jsonToCsv } from '../../utils/jsonToCsv';

const downloadFile = (name, result) => {
  const csvContent = `data:text/csv;charset=utf-8,${result}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${name}.csv`);
  link.click();
};

const openWarning = t => {
  notification.warning({
    message: t('emptyMonthWarningTitle'),
    description: t('emptyMonthWarningBody'),
  });
};

const generateCSV = (date, additionalString, isHydrogen) => {
  let fields;
  let fieldNames;
  if (isHydrogen) {
    fields = ['date', 'consumptionKW', 'consumptionM3'];
    fieldNames = ['Period', 'Consumption kWh', 'Consumption m3'];
  } else {
    fields = [
      'date',
      'consumptionKW',
      'productionKW',
      'consumptionM3',
      'productionM3',
    ];
    fieldNames = [
      'Period',
      'Consumption kWh',
      'Production kWh',
      'Consumption m3',
      'Production m3',
    ];
  }

  const currentDate = moment(date).startOf('month');
  const endDate = moment(date).endOf('month');
  const data = [];

  while (currentDate.isBefore(endDate)) {
    if (isHydrogen) {
      data.push({
        date: currentDate.format('DD.MM.YYYY HH:mm'),
        consumptionKW: 0.0,
        consumptionM3: 0.0,
      });
    } else {
      data.push({
        date: currentDate.format('DD.MM.YYYY HH:mm'),
        consumptionKW: 0.0,
        productionKW: 0.0,
        consumptionM3: 0.0,
        productionM3: 0.0,
      });
    }

    currentDate.add(1, 'hours');
  }

  const csv = jsonToCsv({
    data,
    fields,
    fieldNames,
  });

  return `${additionalString}\n${csv}`;
};

class MeasuringSample extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    legalEntityName: PropTypes.string,
    legalEntityAddress: PropTypes.string,
  };

  state = {
    selectedMonth: null,
  };

  handleChange = momentObject => {
    this.setState({
      selectedMonth: momentObject,
    });
  };

  handleClick = () => {
    const { t, legalEntityAddress, legalEntityName, isHydrogen } = this.props;
    const { selectedMonth } = this.state;

    const additionalName = `Company name: "${legalEntityName}"`;
    const additionalAddress = `Company address: "${legalEntityAddress || ''}"`;
    const additionalString = `${additionalName};${additionalAddress};;;`;

    if (selectedMonth) {
      downloadFile(
        selectedMonth.format('YYYY-MM'),
        generateCSV(selectedMonth, additionalString, isHydrogen)
      );
    } else {
      openWarning(t);
    }
  };

  render() {
    const { t } = this.props;
    const { selectedMonth } = this.state;

    return (
      <div className="componentMeasuringSample">
        <DatePicker.MonthPicker
          className={'pb-1'}
          onChange={this.handleChange}
          placeholder={t('selectMonthPlaceholder')}
          value={selectedMonth}
        />
        <Button icon="download" onClick={this.handleClick}>
          {t('downloadButton')}
        </Button>
      </div>
    );
  }
}

export default MeasuringSample;
