import { formGoReportFields } from '../../constants/forms/formGoReport';
import { Fields } from 'redux-form/immutable';
import { MonthRangeField, MonthYearField } from '../../atoms';
import React from 'react';
import moment from 'moment';
import { FORMAT_DEFAULT_MONTH, links } from '../../utils';
import { goReportFields } from '../../constants/GoReport';
import { numberFormatter } from '../../utils/formaters';
import TableRowActions from '../../components/TableRowActions/TableRowActions';
import { Button, Col, Radio, Row } from 'antd';
import { gas } from '../../constants/classificators';

const BaseGoReport = Component =>
  class extends Component {
    setFormValues() {
      const { formValues } = this.props;
      const { monthFilter } = this.state;

      const params = this.deleteFilterValues(formValues, monthFilter);
      return params;
    }

    deleteFilterValues(formValues, monthFilter) {
      const params = {};

      if (formValues) {
        Object.assign(
          params,
          Object.entries(formValues.toJS()).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          )
        );
        if (monthFilter) {
          delete params[formGoReportFields.DATE_FROM];
          delete params[formGoReportFields.DATE_TO];
        } else {
          delete params[formGoReportFields.MONTH];
          delete params[formGoReportFields.YEAR];
        }
      }
      return params;
    }

    handleGenerate = async newPage => {
      const { goReport } = this.props;
      const { formValues } = this.props;
      const { monthFilter } = this.state;
      const { fetchReportGoItemsPage } = this.props;
      const { location } = this.props;
      const { pageSize, page } = goReport.toJSON();

      const params = this.deleteFilterValues(formValues, monthFilter);

      const query = Object.fromEntries(new URLSearchParams(location.search));
      if (query.transactionId) {
        params[formGoReportFields.TRANSACTION_ID] = query.transactionId;
      }
      if (newPage) {
        params[formGoReportFields.PAGE] = newPage - 1;
      } else {
        if (page) {
          params[formGoReportFields.PAGE] = page - 1;
        } else {
          params[formGoReportFields.PAGE] = 0;
        }
      }
      params[formGoReportFields.PAGE_SIZE] = pageSize || 20;

      this.setState({ loading: true });

      await fetchReportGoItemsPage(params);

      this.setState({ loading: false });
    };

    state = {
      filter: true,
      loading: false,
      monthFilter: true,
      detailModalVisible: false,
      goReportDetails: {},
    };
    handleClear = () => {
      this.props.reset();
    };
    transformHydrogenType = value => {
      const { t } = this.props;

      return t('goReport.estonianTranslations.hydrogenType.' + value);
    };
    transformTransactionStatus = value => {
      const { t } = this.props;
      return t('goReport.estonianTranslations.transactionStatus.' + value);
    };
    handleShowDetailsModal = details => {
      this.setState({ goReportDetails: details });
      this.setState({ detailModalVisible: true });
    };

    hideDetailModal = () => {
      this.setState({ detailModalVisible: false });
      this.setState({ goReportDetails: {} });
    };

    renderFilterField = () => {
      const { t } = this.props;
      const { monthFilter } = this.state;
      if (monthFilter) {
        return (
          <Fields
            names={[formGoReportFields.MONTH, formGoReportFields.YEAR]}
            className="date-field"
            component={MonthYearField}
            hasFeedback={false}
          />
        );
      }
      return (
        <Fields
          names={[formGoReportFields.DATE_FROM, formGoReportFields.DATE_TO]}
          placeholder={[
            t('goReport.dateRange.dateFromPlaceholder'),
            t('goReport.dateRange.dateToPlaceholder'),
          ]}
          component={MonthRangeField}
          hasFeedback={false}
        />
      );
    };

    onPageChange = page => {
      return this.handleGenerate(page);
    };

    // ugly workaround for empty formValues in componentDidMount
    waitInit() {
      if (!this.props.formValues) {
        setTimeout(() => {
          this.waitInit();
        }, 100);
      } else {
        this.handleGenerate(0);
      }
    }

    componentDidMount() {
      this.waitInit();
    }

    firstDetailsColumn() {
      const { t } = this.props;

      return (
        <>
          <div>
            <b>{t('goReport.transactionDate')}: </b>
            {this.state.goReportDetails[goReportFields.TRANSACTION_DATE]}
          </div>
          <div>
            <b>{t('goReport.transactionNumber')}: </b>
            {this.state.goReportDetails[goReportFields.TRANSACTION_NUMBER]}
          </div>
          <div>
            <b>{t('goReport.transactionStatus')}: </b>
            {this.transformTransactionStatus(
              this.state.goReportDetails[goReportFields.TRANSACTION_STATUS]
            )}
          </div>
          <div>
            <b>{t('goReport.cancelledGoCount')}: </b>
            {this.state.goReportDetails[goReportFields.CANCELLED_GO_COUNT]}
          </div>
          <div>
            <b>{t('goReport.naturalEnergyAmount')}: </b>
            {numberFormatter(
              this.state.goReportDetails[goReportFields.NATURAL_ENERGY_AMOUNT]
            )}
          </div>
          <div>
            <b>{t('goReport.calculatedMultiplier')}: </b>
            {this.state.goReportDetails[
              goReportFields.CALCULATED_MULTIPLIER
            ]?.toString()}
          </div>
          <div>
            <b>{t('goReport.calculatedEnergyAmount')}: </b>
            {numberFormatter(
              this.state.goReportDetails[
                goReportFields.CALCULATED_ENERGY_AMOUNT
              ]
            )}
          </div>
          <div>
            <b>{t('goReport.consumptionPeriod')}: </b>
            {this.state.goReportDetails[goReportFields.CONSUMPTION_PERIOD]}
          </div>
        </>
      );
    }
    productionPeriodAndEicw() {
      const { t } = this.props;

      return (
        <>
          <div>
            <b>{t('goReport.productionPeriod')}: </b>
            {this.state.goReportDetails[goReportFields.PRODUCTION_PERIOD]}
          </div>
          <div>
            <b>{t('goReport.eicwCode')}: </b>
            {this.state.goReportDetails[goReportFields.EICW_CODE]}
          </div>
        </>
      );
    }
    firstColumns(columns) {
      const { t } = this.props;

      columns.push(
        {
          title: t('goReport.transactionDate'),
          dataIndex: goReportFields.TRANSACTION_DATE,
        },
        {
          title: t('goReport.transactionNumber'),
          dataIndex: goReportFields.TRANSACTION_NUMBER,
        },
        {
          title: t('goReport.cancelledGoCount'),
          dataIndex: goReportFields.CANCELLED_GO_COUNT,
        },
        {
          title: t('goReport.naturalEnergyAmount'),
          dataIndex: goReportFields.NATURAL_ENERGY_AMOUNT,
          render: numberFormatter,
        },
        {
          title: t('goReport.calculatedEnergyAmount'),
          dataIndex: goReportFields.CALCULATED_ENERGY_AMOUNT,
          render: numberFormatter,
        }
      );
    }
    lastColumns(columns) {
      const { t } = this.props;

      columns.push(
        {
          title: t('goReport.transactionStatus'),
          dataIndex: goReportFields.TRANSACTION_STATUS,
          render: this.transformTransactionStatus,
        },
        {
          dataIndex: goReportFields.ID,
          render: (value, row) => (
            <TableRowActions>
              <Button
                shape="circle"
                icon="select"
                size="small"
                type="primary"
                title={t('goReport.viewBtn')}
                onClick={() => this.handleShowDetailsModal(row)}
              />
            </TableRowActions>
          ),
        }
      );
    }
    getBiomethaneReportColumns = () => {
      const { t } = this.props;
      const columns = [];
      this.firstColumns(columns);
      columns.push(
        {
          title: t('goReport.feedstock'),
          dataIndex: goReportFields.FEEDSTOCK,
        },
        {
          title: t('goReport.consumptionPeriod'),
          dataIndex: goReportFields.CONSUMPTION_PERIOD,
        }
      );
      this.lastColumns(columns);
      return columns;
    };

    getBiomethaneGenerateXlsUrl() {
      const params = this.setFormValues.call(this);
      params['gasType'] = gas.BIOMETHANE;
      const paramString = new URLSearchParams(params);
      return links.reportGoXls + '?' + paramString.toString();
    }

    renderBiomethaneGoReportDetails = () => {
      const { t } = this.props;

      return (
        <div>
          <Row className="global-margin-bottom-10">
            <Col span={8}>{this.firstDetailsColumn.call(this)}</Col>
            <Col span={8}>
              {this.productionPeriodAndEicw.call(this)}
              <div>
                <b>{t('goReport.posNumber')}: </b>
                {this.state.goReportDetails[goReportFields.POS_NUMBER]}
              </div>
              <div>
                <b>{t('goReport.energyType')}: </b>
                {this.state.goReportDetails[goReportFields.ENERGY_TYPE]}
              </div>
              <div>
                <b>{t('goReport.biofuelComponentCn')}: </b>
                {
                  this.state.goReportDetails[
                    goReportFields.BIOFUEL_COMPONENT_CN
                  ]
                }
              </div>
              <div>
                <b>{t('goReport.sustainableBiofuel')}: </b>
                {this.state.goReportDetails[goReportFields.SUSTAINABLE_BIOFUEL]
                  ? 'Jah'
                  : 'Ei'}
              </div>
              <div>
                <b>{t('goReport.fuelType')}: </b>
                {this.state.goReportDetails[goReportFields.FUEL_TYPE]}
              </div>
              <div>
                <b>{t('goReport.feedstock')}: </b>
                {this.state.goReportDetails[goReportFields.FEEDSTOCK]}
              </div>
              <div>
                <b>{t('goReport.productionPathway')}: </b>
                {this.state.goReportDetails[goReportFields.PRODUCTION_PATHWAY]}
              </div>
            </Col>
            <Col span={8}>
              <div>
                <b>{t('goReport.amount')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[goReportFields.AMOUNT]
                )}
              </div>
              <div>
                <b>{t('goReport.lowerCalorificValueKg')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[
                    goReportFields.LOWER_CALORIFIC_VALUE_KG
                  ]
                )}
              </div>
              <div>
                <b>{t('goReport.higherCalorificValueKg')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[
                    goReportFields.HIGHER_CALORIFIC_VALUE_KG
                  ]
                )}
              </div>
              <div>
                <b>{t('goReport.ghgCapacity')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[goReportFields.GHG_CAPACITY]
                )}
              </div>
              <div>
                <b>{t('goReport.biofuelType')}: </b>
                {t(
                  'goReport.estonianTranslations.fuelGeneration.' +
                    this.state.goReportDetails[goReportFields.BIOFUEL_TYPE]
                )}
              </div>
              <div>
                <b>{t('goReport.landUseCategory')}: </b>
                {t(
                  'goReport.estonianTranslations.landUseCategory.' +
                    this.state.goReportDetails[goReportFields.LAND_USE_CATEGORY]
                )}
              </div>
              <div>
                <b>{t('goReport.landUseEmissions')}: </b>
                {this.state.goReportDetails[goReportFields.LAND_USE_EMISSIONS]}
              </div>
              <div>
                <b>{t('goReport.productionUnitStartUsageDate')}: </b>
                {
                  this.state.goReportDetails[
                    goReportFields.PRODUCTION_UNIT_START_USAGE_DATE
                  ]
                }
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    getHydrogenGenerateXlsUrl() {
      const params = this.setFormValues.call(this);
      params['gasType'] = gas.HYDROGEN;
      const paramString = new URLSearchParams(params);
      return links.reportGoXls + '?' + paramString.toString();
    }

    renderHydrogenGoReportDetails = () => {
      const { t } = this.props;

      return (
        <div>
          <Row className="global-margin-bottom-10">
            <Col span={8}>{this.firstDetailsColumn.call(this)}</Col>
            <Col span={8}>
              {this.productionPeriodAndEicw.call(this)}
              <div>
                <b>{t('goReport.hydrogenType')}: </b>
                {this.transformHydrogenType(
                  this.state.goReportDetails[goReportFields.BIOFUEL_TYPE]
                )}
              </div>
              <div>
                <b>{t('goReport.isRFNBO')}: </b>
                {this.state.goReportDetails[goReportFields.RFNBO]
                  ? 'Jah'
                  : 'Ei'}
              </div>
              <div>
                <b>{t('goReport.productionTechnology')}: </b>
                {t(
                  'goReport.estonianTranslations.hydrogenTechnologyType.' +
                    this.state.goReportDetails[
                      goReportFields.HYDROGEN_TECHNOLOGY_TYPE
                    ]
                )}
              </div>
              <div>
                <b>{t('goReport.amount')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[goReportFields.AMOUNT]
                )}
              </div>
              <div>
                <b>{t('goReport.higherCalorificValueKg')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[
                    goReportFields.HIGHER_CALORIFIC_VALUE_KG
                  ]
                )}
              </div>
              <div>
                <b>{t('goReport.lowerCalorificValueKg')}: </b>
                {numberFormatter(
                  this.state.goReportDetails[
                    goReportFields.LOWER_CALORIFIC_VALUE_KG
                  ]
                )}
              </div>
            </Col>
            <Col span={8}>
              <div>
                <b>{t('goReport.ghgCapacity')}: </b>
                {this.state.goReportDetails[
                  goReportFields.GHG_CAPACITY
                ]?.toString()}
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    getHydrogenReportColumns = () => {
      const { t } = this.props;
      const columns = [];
      this.firstColumns(columns);
      columns.push(
        {
          title: t('goReport.hydrogenType'),
          dataIndex: goReportFields.BIOFUEL_TYPE,
          render: this.transformHydrogenType,
        },
        {
          title: t('goReport.consumptionPeriod'),
          dataIndex: goReportFields.CONSUMPTION_PERIOD,
        }
      );
      this.lastColumns(columns);
      return columns;
    };

    renderFirstRows(monthFilter, t) {
      return (
        <>
          <Row gutter={32}>
            <Col span={8}>
              <Radio.Group
                name="filterModeGroup"
                value={monthFilter}
                onChange={e => this.setState({ monthFilter: e.target.value })}
              >
                <Radio value>{t('goReport.filterModeGroup.monthMode')}</Radio>
                <Radio value={false}>
                  {t('goReport.filterModeGroup.rangeMode')}
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={8}>{this.renderFilterField()}</Col>
          </Row>
        </>
      );
    }

    createAndClearButtons(t, location) {
      return (
        <>
          <Button onClick={this.handleClear}>
            {t('goReport.emptyButton')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              //remove transactionId from query if any present
              this.props.history.replace(location.pathname);
              this.handleGenerate();
            }}
          >
            {t('goReport.generate')}
          </Button>
        </>
      );
    }
  };

export const calcInitialDates = () => {
  return moment().month() === 0
    ? {
        [formGoReportFields.MONTH]: 12, // december
        [formGoReportFields.YEAR]: new Date().getFullYear() - 1,
        [formGoReportFields.DATE_FROM]: moment()
          .subtract(1, 'years')
          .startOf('year')
          .format(FORMAT_DEFAULT_MONTH),
        [formGoReportFields.DATE_TO]: moment()
          .subtract(1, 'years')
          .endOf('year')
          .format(FORMAT_DEFAULT_MONTH),
      }
    : {
        [formGoReportFields.MONTH]: new Date().getMonth(), // previous month
        [formGoReportFields.YEAR]: new Date().getFullYear(),
        [formGoReportFields.DATE_FROM]: moment()
          .startOf('year')
          .format(FORMAT_DEFAULT_MONTH),
        [formGoReportFields.DATE_TO]: moment().format(FORMAT_DEFAULT_MONTH),
      };
};

export default BaseGoReport;
