/**
 * Converts an array of objects to a CSV string with optional quotes and custom delimiter.
 * Handles empty values and customizable headers.
 */
export const jsonToCsv = ({
  data,
  fields,
  fieldNames,
  quotes = '',
  del = ';',
}) => {
  if (!data || !Array.isArray(data) || data.length === 0) return '';

  const header = fieldNames.map(name => `${quotes}${name}${quotes}`).join(del);

  const rows = data.map(row =>
    fields
      .map(
        field =>
          `${quotes}${row[field] !== undefined ? row[field] : ''}${quotes}`
      )
      .join(del)
  );

  return [header, ...rows].join('\n');
};
