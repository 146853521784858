import React from 'react';
import { Button, Modal } from 'antd';
import { fileDownload } from '../../utils/fileDownload';

export default function VDSMessageDetailsModal({
  show,
  details,
  hideModal,
  t,
}) {
  return (
    <Modal
      onCancel={hideModal}
      visible={!!show}
      footer={false}
      title={t('details.title')}
      width="80vw"
    >
      <h2>
        {t('details.request')}
        <Button
          className={'pull-right btn-link'}
          onClick={() => fileDownload(details.requestXml, 'request.xml')}
        >
          {t('details.download')}
        </Button>
      </h2>
      <pre className={'block scroll-block vh-30'}>
        {details && details.requestXml}
      </pre>
      <h2>
        {t('details.response')}
        <Button
          className={'pull-right btn-link'}
          onClick={() => fileDownload(details.responseXml, 'response.xml')}
          disabled={!details || !details.responseXml}
        >
          {t('details.download')}
        </Button>
      </h2>
      {details && details.responseXml ? (
        <pre className={'block scroll-block vh-30'}>{details.responseXml}</pre>
      ) : (
        <div className={'block vh-30 text-center pt-4'}>
          {t('details.noResponse')}
        </div>
      )}
    </Modal>
  );
}
