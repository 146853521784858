import React, { Component } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { numberFormatter } from '../../utils/formaters';
import {
  translateBooleanClassificator,
  translateClassificator,
  translateFuelTypeClassificator,
  translateHydrogenFuelTypeClassificator,
  translateHydrogenTechnologyTypeClassificator,
} from '../../utils/translateHelpers';
import moment from 'moment';
import { fuelType as fuelTypeClassifier } from '../../constants/classificators';

export class TransportStatisticsDetailsModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    agreementData: PropTypes.object.isRequired,
    transportStatisticsData: PropTypes.object.isRequired,
    isTransportStatisticsDataLoading: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
  };

  renderDetailRow = (label, value) => (
    <div>
      <b>{this.props.t(label)}: </b>
      {value}
    </div>
  );

  renderModalDetails = () => {
    const {
      t,
      agreementData,
      transportStatisticsData,
      isTransportStatisticsDataLoading,
    } = this.props;
    const {
      expirationDate,
      naturalAmountMj,
      calculatedMultiplier,
      calculatedAmountMj,
      fuelGeneration,
      ghgCapacity,
      ghgAmount,
      consumptionMonth,
      consumptionYear,
      consumptionProvider,
      consumptionProviderTransactionId,
      productionPeriod,
      productionUnitEicwCode,
      posNumber,
      energyType,
      biofuelComponentCn,
      sustainableBiofuel,
      fuelType,
      feedstock,
      productionPathway,
      lowerCalorificValueKg,
      higherCalorificValueKg,
      landUseCategory,
      landUseEmissions,
      hydrogenTechnologyType,
      rfnbo,
    } = transportStatisticsData;

    const isBioMethane =
      agreementData.fuelType === fuelTypeClassifier.BIOMETHANE;
    const isHydrogen = agreementData.fuelType === fuelTypeClassifier.HYDROGEN;
    const isElectricity =
      agreementData.fuelType === fuelTypeClassifier.RENEWABLE_ELECTRICITY;
    const isEnergy =
      agreementData.fuelType === fuelTypeClassifier.RENEWABLE_ENERGY;
    const isKHG = false;

    return (
      <Spin spinning={isTransportStatisticsDataLoading}>
        <div>
          <Row className="global-margin-bottom-10">
            <Col>
              {this.renderDetailRow(
                'details.type.title',
                t('details.type.' + agreementData.fuelType)
              )}
              {isHydrogen &&
                this.renderDetailRow(
                  'details.hydrogenType',
                  translateHydrogenFuelTypeClassificator(
                    agreementData.fuelGeneration
                  )
                )}
              {isHydrogen &&
                this.renderDetailRow(
                  'details.rfnbo',
                  translateBooleanClassificator(String(rfnbo))
                )}
              {this.renderDetailRow('details.expirationDate', expirationDate)}
              {!isKHG &&
                this.renderDetailRow(
                  'details.naturalAmountMj',
                  numberFormatter(naturalAmountMj)
                )}
              {!isKHG &&
                this.renderDetailRow(
                  'details.calculatedMultiplier',
                  calculatedMultiplier
                )}
              {!isKHG &&
                this.renderDetailRow(
                  'details.calculatedAmountMj',
                  numberFormatter(calculatedAmountMj)
                )}
              {(isBioMethane || isEnergy) &&
                this.renderDetailRow(
                  'details.biofuelType',
                  translateClassificator('biofuelType')(fuelGeneration)
                )}
              {(isBioMethane || isElectricity || isEnergy) &&
                this.renderDetailRow(
                  'details.ghgCapacity',
                  numberFormatter(ghgCapacity)
                )}
              {isKHG &&
                this.renderDetailRow(
                  'details.ghgAmount',
                  numberFormatter(ghgAmount)
                )}
            </Col>
          </Row>
          {!isKHG && (
            <Row className="global-margin-bottom-10">
              <Col>
                {this.renderDetailRow(
                  'details.consumptionPeriod',
                  `${
                    isBioMethane || isElectricity ? consumptionMonth + '.' : ''
                  }${consumptionYear}`
                )}
                {(isBioMethane || isElectricity || isHydrogen) &&
                  this.renderDetailRow(
                    'details.consumptionProvider',
                    consumptionProvider
                  )}
                {(isBioMethane || isElectricity) &&
                  this.renderDetailRow(
                    'details.consumptionProviderTransactionId',
                    consumptionProviderTransactionId
                  )}
                {(isBioMethane || isHydrogen) &&
                  this.renderDetailRow(
                    'details.productionPeriod',
                    moment(productionPeriod, 'DD.MM.YYYY').format('MM.YYYY')
                  )}
                {(isBioMethane || isHydrogen) &&
                  this.renderDetailRow(
                    'details.productionUnitEicwCode',
                    productionUnitEicwCode
                  )}
                {isBioMethane &&
                  this.renderDetailRow('details.posNumber', posNumber)}
              </Col>
            </Row>
          )}
          {isHydrogen && (
            <Row className="global-margin-bottom-10">
              <Col>
                {this.renderDetailRow(
                  'details.technologyType',
                  translateHydrogenTechnologyTypeClassificator(
                    hydrogenTechnologyType
                  )
                )}
                {this.renderDetailRow(
                  'details.lowerCalorificValueKg',
                  lowerCalorificValueKg
                )}
                {this.renderDetailRow(
                  'details.higherCalorificValueKg',
                  higherCalorificValueKg
                )}
                {this.renderDetailRow(
                  'details.ghgCapacity',
                  numberFormatter(ghgCapacity)
                )}
              </Col>
            </Row>
          )}
          {isBioMethane && (
            <Row className="global-margin-bottom-10">
              <Col>
                {this.renderDetailRow(
                  'details.energyType',
                  translateClassificator('energyType')(energyType)
                )}
                {this.renderDetailRow(
                  'details.biofuelComponentCn',
                  biofuelComponentCn
                )}
                {this.renderDetailRow(
                  'details.sustainableBiofuel',
                  translateClassificator('sustainableBiofuel')(
                    String(sustainableBiofuel)
                  )
                )}
                {this.renderDetailRow(
                  'details.fuelType',
                  translateFuelTypeClassificator(fuelType)
                )}
                {this.renderDetailRow(
                  'details.feedstock',
                  translateClassificator('feedstock')(feedstock)
                )}
                {this.renderDetailRow(
                  'details.productionPathway',
                  translateClassificator('productionPathway')(productionPathway)
                )}
                {this.renderDetailRow(
                  'details.lowerCalorificValueKg',
                  lowerCalorificValueKg
                )}
                {this.renderDetailRow(
                  'details.landUseCategory',
                  translateClassificator('landUseCategory')(landUseCategory)
                )}
                {this.renderDetailRow(
                  'details.landUseEmissions',
                  landUseEmissions
                )}
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    );
  };

  render() {
    const { t, hideModal } = this.props;

    return (
      <Modal
        title={t('details.title')}
        width="400px"
        footer={false}
        onCancel={hideModal}
        visible
      >
        {this.renderModalDetails()}
      </Modal>
    );
  }
}
