import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRole } from '../../reducers/userReducer';
import { isAdmin } from '../../utils/roles';
import { AddEquipmentEdit } from '../../containers';

export class ProductionUnitView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        productionUnitId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    userRole: PropTypes.node.isRequired,
  };

  render() {
    const {
      userRole,
      match: { params: { productionUnitId } },
      location,
    } = this.props;
    const query = Object.fromEntries(new URLSearchParams(location.search));

    return (
      <div className="main-container">
        <AddEquipmentEdit
          isAdmin={isAdmin(userRole)}
          isEditMode
          productionUnitId={productionUnitId}
          queryParams={query}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userRole: getRole(state),
});

export default connect(mapStateToProps)(ProductionUnitView);
