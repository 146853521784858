export default {
  MEASUREMENT_POINT: 'MEASUREMENT_POINT',
  MEASUREMENT_POINT_REVIEW: 'MEASUREMENT_DATA_REVIEW',
  LEGAL_ENTITY: 'LEGAL_ENTITY',
  PRODUCTION_UNIT: 'PRODUCTION_UNIT',
  LOADING_UNIT: 'LOADING_UNIT',
  TS_AGREEMENT: 'TS_AGREEMENT',
  TS_AUCTION: 'TS_AUCTION',
  PRODUCTION_UNIT_TECHNICAL: 'PRODUCTION_UNIT_TECHNICAL',
};
